//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
export default {
  name: "ChangePasswordSuccessful",
  components: {
    LandingPagePopUp
  },
  methods: {
    signIn() {
      this.$router.push({
        name: "sign-in"
      });
    }
  }
};